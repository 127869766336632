<template>
	<div id="conekta-cash-out">
		<div class="content-container">
			<div class="input-section" v-if="selectedCasino?.id">
				<label for="toPlayerCashCOC">{{ languageStrings.webPayCashOutCOC }}</label>
				<span v-if="webPayOptions?.conekta?.cashOutMinimumCOC"
					>Min:
					{{
						systemCurrencyTool.formatCurrency(
							webPayOptions?.conekta?.cashOutMinimumCOC,
							systemCurrencyTool.displayType.minorWholeOrFull
						)
					}} </span
				>&nbsp;
				<span v-if="webPayOptions?.taxAmlRestrictions?.cashOutThresholdCOC"
					>Max:
					{{
						systemCurrencyTool.formatCurrency(
							webPayOptions?.taxAmlRestrictions?.cashOutThresholdCOC,
							systemCurrencyTool.displayType.minorWholeOrFull
						)
					}}</span
				>&nbsp;
				<span>
					<span v-if="systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
						systemCurrencyTool.currencyInfo.symbol
					}}</span>
					<input
						type="number"
						id="toPlayerCashCOC"
						name="displayCOC"
						v-model="toPlayerCashCOC"
						:min="webPayOptions?.conekta?.cashOutMinimumCOC"
						:max="webPayOptions?.taxAmlRestrictions?.cashOutThresholdCOC"
						:placeholder="languageStrings.fromPlayerCash"
						autocomplete="off"
					/>
					<span v-if="!systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
						systemCurrencyTool.currencyInfo.symbol
					}}</span>
				</span>
			</div>
			<div class="button-container">
				<button class="btn buy-in-btn curved-border" id="commit-cash-out" @click="order()">
					{{ languageStrings.webPayCashOut }}
				</button>
			</div>
			<dialog id="conekta-dialog">
				<div>
					<h2>{{ languageStrings.transactionResult }}</h2>

					<p v-if="transaction?.fromPlayerCashCOC">
						Tranfered&nbsp;
						{{
							systemCurrencyTool.formatCurrency(
								Math.abs(transaction.fromPlayerCashCOC),
								systemCurrencyTool.displayType.minorWholeOrFull
							)
						}}
					</p>

					<div id="btn-container">
						<router-link class="btn" title="View Transactions" to="/webPayTransactions">
							View Transactions
						</router-link>
						<router-link class="btn" :title="languageStrings.viewBalances" to="/">{{
							languageStrings.viewBalances
						}}</router-link>
					</div>
				</div>
			</dialog>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "ConektaCashOut",
	inheritAttrs: false,
	props: {
		playerState: Object,
		isMobile: Boolean,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
		systemSettings: Object,
		selectedCasino: Object,
		webPayOptions: Object,
	},
	components: {},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			cashOutCurrency: {},
			inGameCurrencyInfo: {},
			conektaDialog: null,
			casinoId: null,
			conektaShow: false,
			rabbitsfootMessage: null,
			toPlayerCashCOC: null,
			buttonText: "",
			transactionStatus: null,
			transaction: null,
			checkoutStatus: "",
		};
	},
	watch: {},
	created() {
		this.eventBus.on("showConektaWidgit", () => {
			this.conektaShow = true;
		});
		this.eventBus.on("handelConektaError", (payload) => {
			this.rabbitsfootMessage = payload;
			this.conektaShow = false;
			this.showConektaDialog();
		});
		onBeforeUnmount(() => {
			this.eventBus.off("showConektaWidgit");
			this.eventBus.off("handelConektaError");
		});
	},
	mounted() {
		this.conektaDialog = document.getElementById("conekta-dialog");
	},
	methods: {
		async order() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.loadingWebPayCashout;
			this.buttonText = this.languageStrings.cancelTransaction;

			let hasErrors = false;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				hasErrors = true;
			}

			if (!this.toPlayerCashCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustEnterACashOutAmount;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (isNaN(this.toPlayerCashCOC)) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustEnterNumberOnly;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (
				this.toPlayerCashCOC < this.webPayOptions?.conekta?.cashOutMinimumCOC ||
				this.toPlayerCashCOC > this.webPayOptions?.taxAmlRestrictions?.cashOutThresholdCOC
			) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = `Transaction amount allowed is between ${
					this.webPayOptions.conekta.cashOutMinimumCOC
				} and ${
					this.webPayOptions?.taxAmlRestrictions?.cashOutThresholdCOC ||
					this.webPayOptions?.conekta?.cashOutMaximumCOC
				}`;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (
				this.systemCurrencyTool.toAUFromCurrency(this.toPlayerCashCOC) > this.selectedCasino.balance.regularAU
			) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.notEnoughFundsInPlayerAccount;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (this.toPlayerCashCOC < this.webPayOptions.conekta.cashOutMinimumCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustMeetMinimumCashOutValue;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (hasErrors === true) return;

			let body = {
				casinoId: this.webPayOptions.casino.id,
				toPlayerCashCOC: this.toPlayerCashCOC,
				provider: "Conekta",
			};

			let requestUrl = new URL("/api/v1/webpay/cashout/initiate", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}
				let dataJson = await response.json();

				this.transaction = dataJson.transaction;

				if (this.transaction?.status === "EmailNotVerified") {
					console.error(this.transaction?.status);
					this.status.ok = false;
					this.status.message =
						this.languageErrorStrings.emailNotVerifiedPleaseGoToYourUserProfileAndVerifyYourEmail;
					this.eventBus.emit("updateStatus", this.status);
					return;
				}

				if (fetchStatus.ok)
					this.status = sharedScripts.checkSuccessErrors(this.transaction.status, this.languageErrorStrings);

				if (dataJson?.status === "Success") {
					this.status.ok = true;
					this.status.message = this.languageErrorStrings.webPayInitiated;
					this.eventBus.emit("updateStatus", this.status);
					this.showConektaDialog();
				}
			} catch (e) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.webPayInitiateFailed;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
		showConektaDialog() {
			this.conektaDialog.showModal();
		},
		closeConektaDialog() {
			this.toPlayerCashCOC = null;
			this.conektaDialog.close();
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
	text-align: center;
}

.input-section {
	display: flex;
	flex-direction: row;
	align-items: center;
	/* width: 75%; */
	margin-top: 2vh;
	margin-left: auto;
	margin-right: auto;
	justify-content: center;
}

.input-section > span {
	display: flex;
	align-items: center;
}

#conekta-container {
	display: none;
}

#conekta-container.show {
	display: grid;
	align-items: center;
	position: fixed;
	top: 100px;
	right: 0;
	left: 0;
	bottom: 0;
	margin: auto;
	height: calc(100vh - 100px);
	width: 100vw;
	background-color: rgb(0 0 0 / 70%);
	backdrop-filter: blur(6px);
}

#conekta-dialog {
	width: 80%;
	margin: auto;
	background-color: #000;
	color: #fff;
	border-radius: 12px;
	border-width: 1px;
	box-sizing: border-box;
	text-align: center;
}

::backdrop {
	background-color: #000;
	backdrop-filter: blur(8px);
	opacity: 0.75;
}

#btn-container {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}

#btn-container a {
	text-decoration: none;
}

#btn-container * {
	margin: 15px;
}

select#offer-payment-type {
	width: 100%;
	height: 5vh;
	text-align: center;
	margin-left: 6vw;
	color: #5d3fd3;
	font-weight: bolder;
}

#payment-type-section {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 2vh;
	margin-left: 8vw;
	width: 31vw;
}

#balances {
	background: white;
	display: flex;
	align-items: center;
	/* text-align: center; */
	color: #5d3fd3;
	border-radius: 25px;
	width: 65%;
	margin-right: auto;
	margin-left: auto;
	font-size: 17px;
	font-weight: bolder;
	height: 5vh;
	margin-top: 3vh;
}

#toPlayerCashCOC {
	border-radius: 25px;
	/* width: 100%; */
	height: 4.6vh;
	color: #5d3fd3;
	text-align: center;
	margin-right: 3vw;
	min-width: 200px;
}

.button-container {
	background: white;
	border-radius: 15px;
	width: 190px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 4.5vh;
}

.buy-in-btn {
	/* background: linear-gradient(to right, blue, purple, #fa5f55); */
	/* -webkit-text-fill-color: transparent; */
	background-clip: text;
	margin: auto;
	width: 190px;
	height: 4.5vh;
	font-weight: bolder;
	overflow-wrap: break-word;
	/* margin-top: 3vh; */
}

/* .button-container:hover {
	width: 22.5vw;
	padding-left: 6px;
} */

.current-balance {
	margin: 4px auto;
}

iframe {
	margin: auto;
	width: 90%;
	height: 400px;
	border-radius: 12px;
}

@media (min-width: 768px) {
	#cash-out {
		width: 80%;
		margin-left: 20%;
		position: absolute;
	}

	#conekta-dialog {
		width: 50%;
	}
	iframe {
		margin: auto;
		width: 50%;
	}
}

@media (min-width: 1629px) {
	.playerCashCOC {
		margin-left: 5.5vw;
	}
}

@media (max-width: 767px) {
	.button-container {
		background: white;
		border-radius: 15px;
		width: 35vw;
		margin-left: auto;
		margin-right: auto;
		margin-top: 7.5vh;
	}

	/* .button-container:hover {
		width: 36vw;
	} */

	.buy-in-btn {
		/* background: linear-gradient(to right, blue, purple, #fa5f55);
		-webkit-text-fill-color: transparent; */
		background-clip: text;
		margin: auto;
		border-radius: 15px !important;
		width: 35vw;
		height: 4.5vh;
		font-weight: bolder;
		overflow-wrap: break-word;
	}

	.content-container {
		margin-top: 3.5vh;
	}

	#balances {
		width: 90%;
	}

	#toPlayerCashCOC {
		border-radius: 25px;
		width: 100%;
		height: 4.6vh;
		color: #5d3fd3;
		text-align: center;
		margin-right: 0;
	}

	#payment-type-section {
		width: 60%;
		margin-left: auto;
		margin-right: 7vw;
	}

	#payment-type-label {
		margin-left: 9vw;
	}

	select#offer-payment-type {
		width: 21vw;
		height: 5vh;
		text-align: center;
		margin-left: 1vw;
		color: #5d3fd3;
		font-weight: bolder;
	}

	.input-section {
		width: 370px;
	}
}
</style>
